export const ADVISOR_TAG = "apitradeorderAdvisor";
/** Allowed attributes for dangerously inset html */
export const ALLOWED_HTML_ATTRIBUTES = [
  "href",
  "target",
  "rel",
  "style",
  "id",
  "class",
  "src",
  "alt",
  "title",
  "width",
  "height",
  "data-*",
];
